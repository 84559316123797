import {Outlet, useHref} from "react-router-dom";
import {useEffect} from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import {useTranslation} from "react-i18next";
import {api} from "../../../api";
import {useSelector, useDispatch} from "react-redux";
import Notification from "../../components/Notification";
import TabBar from "./TabBar";

//Наша оберточная компонента , которая будет оборачивать все наши страницы, содержить Sidebar, Header и Main
const Layout = () =>{
	const {t} = useTranslation();

	const dispatch = useDispatch();

	const url = useHref();

	useEffect(() =>{
		const getTitle = async () => {
			try{
				const getTitle = await api.cabinetApi.getTitle({"path":url});
				dispatch({type: "CHANGE_TITLE", title: getTitle.title})
			}catch (e){
				console.log(e);
			}
		}
		getTitle();

		//Чистим матчинг при переходе на другую страницу
		if(url !== '/matching'){
			dispatch({type: 'CLEAR_MATCHING'})
		}

		if(!url.startsWith('/catalog')){
			dispatch({type: 'CLEAR_CATALOG'})
		}

		//Чистим выбранную категорию при переходе на другую страницу
		dispatch({type: "SET_CATALOG_CURRENT_CATEGORY_ID", currentCategoryId: null})
		dispatch({type: "SET_MODAL_CURRENT_CATEGORY_ID", modalLevel: 1, currentCategoryId: null})
		// dispatch({type: "CLEAR_CATALOG"})

	}, [url])

	return(
		<>
			<Sidebar/>
			<TabBar />
			<main>
				<Notification />
				<Header/>
				<div className="container">
					<Outlet />
				</div>
			</main>
		</>
	)
}

export default Layout;