const initialState = {
	currentTools : null,
}

export const multitoolsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_MULTITOOLS":
			return {
				...state,
				currentTools: action.currentTools
			};
		default:
			return state;
	}
}