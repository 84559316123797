import {useDispatch, useSelector} from "react-redux";
import plus from '../../../../../images/catalogs/category/plus.svg'
import minus from '../../../../../images/catalogs/category/minus.svg'
import deleteSvg from '../../../../../images/closeModal.svg'
import {api} from "../../../../../api";
import matchChain from '../../../../../images/match-chain.svg'
import matchingProduct from '../../../../../images/matchingProduct.svg'

// Рекурсивный компонент для отображения категорий
const Category = ({category, categories}) => {
	// Находим подкатегории для текущей категории
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level - 1;
	const currentSourceItem = useSelector(state => state.matching.currentSourceItem);
	const currentSourceList = useSelector(state => state.matching.currentSourceList);
	const currentCatalogList = useSelector(state => state.matching.currentCatalogList);
	const categorySourceList = useSelector(state => state.matching.categorySourceList);
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);
	const showSourceProductList = useSelector(state => state.matching.showSourceProductList);

	//Функция для изменения выбранной категории
	const changeCurrentItem = (id) => {
		if (id === currentSourceItem) {
			dispatch({type: 'EDIT_SOURCE_ITEM', currentSourceItem: null})
		} else {
			dispatch({type: 'EDIT_SOURCE_ITEM', currentSourceItem: id})
		}
	}

	// Делаем активной выбранную категорию
	function changeSelectedItem(event, id) {
		if (event.target.closest('.i_catalog-view-product')) {
			// Если цель клика находится внутри i_catalog-view-product, игнорируем обработку клика
			return;
		}
		const updatedList = categories.map(item => {
			if (item.id === id) {
				changeCurrentItem(id)
				return {
					...item,
					is_active: !item.is_active,
					show_match: item.match ? item.is_active === false ? true : !item.show_match : false
				};
			} else {
				return {...item, is_active: false};
			}
		})
		dispatch({type: 'EDIT_CATEGORY_SOURCE_LIST', categorySourceList: updatedList})
	}

	// Если у категории нет дочерних элементов то расскрвыаем ее по нажатию на круг
	function changeIsShow(id) {
		const updatedList = categories.map(item => {
			if (item.id === id) {
				return {...item, is_show: item.has_children ? !item.is_show : false};
			} else {
				return item;
			}
		});
		dispatch({type: 'EDIT_CATEGORY_SOURCE_LIST', categorySourceList: updatedList})
	}


	function openMatch(matchId) {

		let updatedCatalogList = categoryCatalogList;

		function openParentItem(id) {
			const parentItem = updatedCatalogList.find(item => item.id === id);
			if (parentItem && parentItem.parent_id !== 0) {
				openParentItem(parentItem.parent_id);
			}
			if (parentItem) {
				parentItem.is_show = true;
			}
		}

		updatedCatalogList = updatedCatalogList.map(item => {
			if (item.id === matchId) {
				if (item.parent_id !== 0) {
					openParentItem(item.parent_id);
				}
				dispatch({type: 'EDIT_CATALOG_ITEM', currentCatalogItem: item.id})

				// Прокручиваем каталог к целевому элементу
				setTimeout(() => {
					const catalogCatalogItemsBlock = document.querySelector('.i_matching-right .i_catalog-category-items')
					const catalogCatalogItems = catalogCatalogItemsBlock.querySelector('.i_catalog-category-item[data-id="' + matchId + '"]')
					catalogCatalogItems.scrollIntoView({block: "center", behavior: "smooth"})
				}, 100)

				return {
					...item,
					is_active: true,
					show_match: true,
				};
			} else {
				if (item.has_children) {
					return item
				} else {
					return {
						...item,
						is_active: false,
						is_show: false
					};
				}
			}
		});

		dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList})
	}

	const deleteMatchCategory = async (sourceId, categoryId) => {
		let body = {"id": sourceId, "type": currentSourceList.type}
		try {
			const deleteMatch = await api.cabinetApi.deleteMatchCategory(body)
			if (deleteMatch.status === true) {
				try {
					// Удаляем матч из каталога источников
					const updatedSourceList = categories.map(item => {
						if (item.id === deleteMatch.source_category_id) {
							return {...item, match: null, show_match: false, is_show: false};
						} else {
							return item;
						}
					})

					const updatedCatalogList = categoryCatalogList.map(item => {
						if (item.id === deleteMatch.dl_category_id) {
							return {
								...item,
								match: item.match.filter(el => el.id !== deleteMatch.id),
								show_match: item.match.length !== 1,
								is_show: item.match.length !== 1
							};
						} else {
							return item;
						}
					})

					dispatch({
						type: 'EDIT_CATEGORY_SOURCE_LIST',
						categorySourceList: updatedSourceList
					})

					dispatch({
						type: 'EDIT_CATEGORY_CATALOG_LIST',
						categoryCatalogList: updatedCatalogList
					})

					dispatch({
						type: 'SHOW_NOTIFICATION',
						notificationIsOpen: true,
						notificationContent: deleteMatch.message,
						notificationType: 'success'
					});
				} catch (error) {
					console.log(error)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

	//Открываем окно с товарами категорий
	async function showCategoryProduct(categoryId) {
		dispatch({type: 'CHANGE_SOURCE_CATEGORY_ID', currentSourceCategoryId: categoryId})
		try {
			const productList = await api.cabinetApi.getMatchingProductList({
				"type": currentSourceList.type,
				"category_id": categoryId,
				"catalog_code": currentCatalogList.code
			})
			dispatch({type: 'CHANGE_SOURCE_PRODUCT_LIST', sourceProductList: productList})
			dispatch({type: 'CHANGE_SHOW_SOURCE_PRODUCT_LIST', showSourceProductList: !showSourceProductList})
		} catch (e) {
			console.log(e)
		}
	}


	return (
		<div className={`i_catalog-category-item i_catalog-category-item-level-${level} 
			${category.is_show ? 'active' : ''} 
			${!category.has_children ? 'last-child' : ''} 
			${category.show_match ? 'show-match' : ''} 
			${category.is_active ? 'active' : ''}`}
		     style={{paddingLeft: level === 0 ? '15px' : '30px'}}
		     data-id={category.id}

		>
			<div className={`i_catalog-category-item-title ${currentSourceList === category.id ? 'selected' : ''}`}
			     onClick={(event) => {
				     !category.has_children && changeSelectedItem(event, category.id)
			     }}
			>
				{
					category.id !== 0 && (
						<div
							className={`i_catalog-title-circle ${!category.has_children ? '' : 'last-child'} ${currentSourceList === category.id ? 'active' : category.is_active ? 'active' : ''}`}
							onClick={(e) => {
								e.stopPropagation();
								category.has_children && changeIsShow(category.id)
							}}
						>
							{category.is_show && category.has_children ?
								<img src={`${minus}`} alt="-"/> : (!category.has_children ? null :
									<img src={`${plus}`} alt="+"/>)}
						</div>
					)
				}
				{category.name}

				{/*Показываем иконку с матчем в категории если они есть*/}
				{category.match &&
					<div className={'i_title-chain'}>
						<img src={`${matchChain}`} alt={''}/>
					</div>
				}

				{/*Кнопка показа товаров*/}
				{!category.has_children &&
					<div className={'i_catalog-view-product'} onClick={() => {
						showCategoryProduct(category.id)
					}}
					     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Товары'})}
					     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}>
						<img src={`${matchingProduct}`} alt={''}/>
					</div>
				}
			</div>
			{category.match && (
				<>
					<div className={`i_catalog-category-item-match ${category.show_match ? 'show' : 'hide'}`}>
						<div className="i_match-items">
							<div className="i_match-item">
									<span onClick={() => {
										openMatch(category.match.match_id, category.id)
									}}>{category.match.name}</span>
								<div className="i_match-item-delete" onClick={() => {
									deleteMatchCategory(category.match.id, category.id)
								}}>
									<img src={`${deleteSvg}`} alt="delete"/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{
				category.id !== 0 && (
					subCategories.map(subCategory => (
						<Category key={subCategory.id} category={subCategory} categories={categories}/>
					))
				)
			}

		</div>
	);
};

const MatchingSourceCategoryList = () => {
	// Находим корневые категории (parent_id === 0)
	let categorySourceList = useSelector(state => state.matching.categorySourceList);

	const rootCategories = categorySourceList.filter(category => category.parent_id === 0 || category.id === 0);

	return (
		<div>
			{rootCategories.map(category => (
				<Category key={category.id} category={category} categories={categorySourceList}/>
			))}
		</div>
	);
};

export default MatchingSourceCategoryList