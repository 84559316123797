import Skeleton from "react-loading-skeleton";
import {Link, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";

import { api } from "../../../../api";
import { ReactSVG } from 'react-svg';
import editImg from '../../../../images/catalogs/catalog-edit.svg'
import deleteImg from '../../../../images/catalogs/catalog-trash.svg'
import plus from '../../../../images/catalogs/plus.svg'
import "../styles/catalogPage.scss"

import {useDispatch, useSelector} from "react-redux";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";


const CatalogPage = () => {
	const [isLoading, setIsLoading] = useState(true)

	const catalogList = useSelector(state => state.catalog.distributor.catalogList);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const showEditCatalogModal = (event, name , code) =>{
		event.stopPropagation()
		dispatch({type: "OPEN_MODAL", modalType: 'editCatalogModal', modalLevel: 1, modalData: {name: name, code: code}})
	}

	const showPopupDelete = (event, code) =>{
		event.stopPropagation()
		dispatch({type:'SWITCH_POPUP', popupType: 'deleteDistributorCatalog', popupIsOpen: true, currentCatalogItem: code})
	}

	//Переход на страницу каталога
	const openCatalog = (code) => {
		navigate(`/catalogs/${code}`);
	}

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const distributorCatalogList = await api.cabinetApi.getCatalogList();
			dispatch({type: "REFRESH_CATALOG_LIST", catalogList: distributorCatalogList.catalogs})
			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getList()
	}, [])

	// useEffect(() =>{
	// 	setIsLoading(true)
	//
	// 	setTimeout(() =>{
	// 		setIsLoading(false)
	// 	}, 700)
	// },[catalogList])

	return (
		<div className={`i_catalog-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height: "100%"}}>
						<Skeleton
							count={10}
							height={'340px'}
							width={'19%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-skeleton'}
						/>
					</div>
				</>
			)}
			{!isLoading && catalogList && (
				<div className="i_catalog-items distributors-catalogs">
					{catalogList.map((item, index) => {
						return (
							<div className={'i_catalog-item'} key={index} onClick={()=>{openCatalog(item.code)}}>
								<div className="i_catalog-item-name">
									<Link to={`/catalogs/${item.code}/`}>
										<span>{item.name}</span>
									</Link>
								</div>
								<div className="i_catalog-item-code">
									<span>Код: {item.code}</span>
									<CopyToClipboardButton textToCopy={item.code}/>
								</div>
								<div className="i_catalog-item-stats">
									<div className="i_catalog-item-category-active">
										<span>Категорий: {item.category}</span>
									</div>
									<div className="i_catalog-item-product-active">
										<span>Товаров: {item.product}</span>
									</div>
								</div>
								<div className="i_catalog-item-btn">
									<div className="i_catalog-item-btn-edit" onClick={(event) => {
										showEditCatalogModal(event, item.name, item.code)
									}}>
										<span>
											<ReactSVG src={editImg} className={'i_main-widgets-svg'}/>
										</span>
									</div>
									<div className="i_catalog-item-btn-trash" onClick={(event) => {
										showPopupDelete(event, item.code)
									}}>
										<span>
											<ReactSVG src={deleteImg} className={'i_main-widgets-svg'}/>
										</span>
									</div>
								</div>
								<div className="i_catalog-item-date">
									<span>Дата обновления  <br/>{item.date_update}</span>
								</div>
							</div>
						)
					})}
					<div className={'i_catalog-item add-item'} onClick={() => {
						dispatch({type: "OPEN_MODAL", modalType: 'addCatalogModal', modalLevel: 1})
					}}>
						<ReactSVG src={plus} className={'i_main-widgets-svg'}/>
					</div>
				</div>
			)}

		</div>
	)
}

export default CatalogPage;