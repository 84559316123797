import InputMask from "react-input-mask";
import {COUNTRY_CONFIG} from "../../../utils/config";
import {useSelector} from "react-redux";


//Кастомный инпут
const CustomInput = ({ title, value, onChange, isMobile, isBin}) => {

	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	// const siteID = 'kz'


	//Используем маску для мобильных устройств если isModile = true, иначе обычный инпут
	return (
		<>
			<div className={'i_input'}>
				<label>{title}</label>
				{isMobile ? (
					<InputMask
						mask="+7 (999)-999-99-99"
						maskchar={null}
						value={value}
						alwaysShowMask={false}
						formatchars={{ '9': '[0-9]' }}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					/>
				) : isBin ?
					<>
						<InputMask
							mask={COUNTRY_CONFIG[siteID].bin}
							maskchar={null}
							maskPlaceholder={null}
							value={value}
							alwaysShowMask={false}
							formatchars={{ '9': '[0-9]' }}
							onChange={(e) => {
								onChange(e.target.value);
							}}
						/>
					</>
					: (
					<input type="text" value={value} onChange={(e) => onChange(e.target.value) }/>
				)}
			</div>
		</>
	);
};

export default CustomInput;
