import closeProfile from "../../../images/closeModal.svg";
import {Link, useNavigate} from "react-router-dom";
import avatar from "../../../images/avatar.svg";
import {useEffect, useRef, useState} from "react";
import {api} from "../../../api";
import {useSelector} from "react-redux";


const Profile = () => {
	const userData = useSelector(state => state.userData.data)
	const role = useSelector(state => state.userData.data.entity.type)

	const [profileActive, setProfileActive] = useState(false)
	const [profileHeight, setProfileHeight] = useState(null)
	const [profileWidth, setProfileWidth] = useState(null)
	const [companyActive, setCompanyActive] = useState(false)
	const [companyHeight, setCompanyHeight] = useState(null)
	const [companyWidth, setCompanyWidth] = useState(null)

	const profileHeadRef = useRef(null)
	const profileMoreRef = useRef(null)
	const profileRef = useRef(null)
	const companyRef = useRef(null)
	const companyHeadRef = useRef(null)
	const companyMoreRef = useRef(null)

	// Хук для перехода по ссылке
	const navigate = useNavigate();

	// useEffect для получения высоты Profile
	useEffect(() => {
		if (profileMoreRef.current) {
			const blockHeight = profileMoreRef.current.clientHeight;
			setProfileHeight(blockHeight);
		}
		if(profileHeadRef.current){
			const blockWidth = profileHeadRef.current.clientWidth;
			setProfileWidth(blockWidth);
		}
	}, [profileActive]);

	// useEffect для получения высоты Company
	useEffect(() => {
		if (companyMoreRef.current) {
			const blockHeight = companyMoreRef.current.clientHeight;
			setCompanyHeight(blockHeight);
		}
		if(companyHeadRef.current){
			setTimeout(() => {
				const blockWidth = companyHeadRef.current.clientWidth;
				setCompanyWidth(blockWidth);
			},100)
		}
	}, [companyActive]);

	// useEffect для обработки клика за пределами компонента
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (profileRef.current && !profileRef.current.contains(event.target)) {
				setProfileActive(false);
			}
			if(companyRef.current && !companyRef.current.contains(event.target)){
				setCompanyActive(false)
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);




	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			setProfileActive(false);
			setCompanyActive(false)
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);


	//Метод для выхода пользователя
	const logoutUser = async () =>{
		try{
			const result = await api.authApi.logoutUser();
			if(result.status === true){
				const result = await api.authApi.logoutUser();
				if(result.status === true){
					window.location.reload();
				}
			}
		}catch (e) {
			console.log(e)
		}
	}

	return (
		<div className="header-items">
			<div className="header-item multitools">
				{/*<MultiTools/>*/}
			</div>
			<div className={`header-item company-name ${companyActive ? 'active' : ''}`} ref={companyRef}
			     style={{
				     minWidth: `${companyActive ? companyWidth + 75 : companyWidth + 25}px`
			     }}
			>
				<div className="header-item-company" style={{
					height: `${companyActive ? companyHeight + 70 : 36}px`,
					borderRadius: `${companyActive ? 15 : 80}px`,
					minWidth: `${companyActive ? companyWidth + 75 : companyWidth + 25}px`
				}}>
					<div className="company-head">
						<div className="company-head-left" onClick={() => {
							setCompanyActive(true)
						}} ref={companyHeadRef}>
								<span className={'company-name-title'}>
									{userData.entity.name}
								</span>
							<span className={'company-role-title'}>
										<span className="circle"></span>
								{role === 'distributor' ? 'дистрибьютор' : 'дилер'}
									</span>
						</div>
						<div className="company-close" onClick={() => {
							setCompanyActive(false)
						}}>
							<img src={closeProfile} alt="close"/>
						</div>
					</div>
					<div className="company-more" ref={companyMoreRef}>
						<div className="company-more-item">
							<span className={'dotted'}></span>
							<Link className={'item-value'} to={'/profile/'} onClick={() => {
								setCompanyActive(false)
							}}><span>Профиль</span></Link>
						</div>
						<div className="company-more-item">
							<span className={'dotted'}></span>
							<Link className={'item-value'} to={'/billing/'} onClick={() => {
								setCompanyActive(false)
							}}><span>Биллинг</span></Link>
						</div>
					</div>
				</div>
			</div>
			<div className={`header-item profile ${profileActive ? 'active' : ''}`} style={{
				minWidth: `${profileWidth + 35}px`
			}} ref={profileRef}>
				<div className="header-item-profile" style={{
					height: `${profileActive ? profileHeight + 70 : 36}px`,
					borderRadius: `${profileActive ? 15 : 80}px`,
					minWidth: `${profileActive ? profileWidth + 75 : profileWidth + 25}px`
				}}>
					<div className="profile-head">
						<div className="profile-left" onClick={() => {
							setProfileActive(true)
						}} ref={profileHeadRef}>
							<img src={avatar} alt="avatar"/>
							<span>{userData.user.login}</span>
						</div>

						<div className="profile-close" onClick={() => {
							setProfileActive(false)
						}}>
							<img src={closeProfile} alt="close"/>
						</div>
					</div>
					<div className="profile-more" ref={profileMoreRef}>
						<div className="profile-more-item" onClick={() => {
							logoutUser()
						}}>
							<span className={'dotted'}></span>
							<span className={'profile-value'}>Выход</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Profile;