import React, { useState } from 'react';

const SelectInput = ({ value, title, checkBoxValue, inputChecked}) => {
	const [isChecked, setIsChecked] = useState(!!inputChecked);

	const handleInputChange = () => {
		// Обновляем состояние isChecked при изменении чекбокса
		const newCheckedValue = !isChecked;
		setIsChecked(newCheckedValue);

		// Если новое значение isChecked равно true, выводим значение в консоль
		checkBoxValue({value:value, isChecked: newCheckedValue});
	};

	return (
		<div className={'i_select-input-box'}>
			<label className="i-select-contain">
				<span>{title}</span>
				<input
					type="checkbox"
					value={value}
					checked={isChecked}
					onChange={handleInputChange}
				/>
				<div className="i-select-input"></div>
			</label>
		</div>
	);
};

export default SelectInput;
