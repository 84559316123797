import {useState} from "react";
import {api} from "../api";
import {useDispatch, useSelector} from "react-redux";
import ButtonLoader from "../pages/main/components/ButtonLoader";

const EditCatalogModal = () =>{
	const dispatch = useDispatch();

	//Достаем данные из стейта для изменения каталога
	const modalData = useSelector(state => state.multiModal.modals[0].modalData);

	//Состояния для инпутов
	const [name, setName] = useState(modalData.name)
	const code = modalData.code

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		//Создаем тело запроса
		let body = {
			"code":code,
			"name":name
		}

		try {
			const result = await api.cabinetApi.editCatalog(body);

			if(result.status === true) {
				const distributorCatalogList = await api.cabinetApi.getCatalogList();
				dispatch({type: "REFRESH_CATALOG_LIST", catalogList: distributorCatalogList.catalogs})
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

			}else{
				setError(result.message)
			}

		} catch (error) {

			console.log(error);

		}finally {
			setTimeout(() => {
				setLoad(false)
			},700)

		}
	}

	return(
		<div className={'i_add-catalog-modal'}>
			<div className="i_add-catalog-modal-title">
				<span>Изменить каталог</span>
			</div>
			<div className="i_add-catalog-modal-items">
				<div className="i_add-catalog-modal-code">
					<span>Код: {code}</span>
				</div>
				<div className="i_add-catalog-modal-item">
					<label>Название каталога</label>
					<input type="text" onChange={(e) => {setName(e.target.value)}}
					       value={name}
					/>
				</div>
			</div>

			<div className="i_add-catalog-modal-btn" onClick={formSubmit}>
				<ButtonLoader load={load} title={'Изменить'}/>
			</div>

			{error !== '' ? <div className={`i_add-catalog-modal-error`}>
				<span>{error}</span>
			</div> : ''}
		</div>
	)
}

export default EditCatalogModal