import {useSelector, useDispatch} from "react-redux";
import {api} from "../../../../../api";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import infoImg from "../../../../../images/info.svg";

const SourceProductList = ({productList}) => {
	const dispatch = useDispatch();
	const nextOffset = useSelector(state => state.catalog.productList.nextOffset);
	const currentCategoryId = useSelector(state => state.catalog.productList.currentCategoryId);
	let { code} = useParams();
	const [showMore, setShowMore] = useState(true);

	useEffect(() => {
		if (nextOffset === null || nextOffset === 'undefined') {
			setShowMore(false)
		}else if(nextOffset >= 100){
			setShowMore(true)
		}
	}, [nextOffset])

	//Функция для открытия модального окна с информацией о товаре
	const openCatalogDetailModal = (id) => {
		dispatch({type: "OPEN_MODAL", modalType: "sourceCatalogProductDetailModal", modalLevel: 1, modalData: {productId : id}, modalWidth: 'large'})
	}

	//Функция для подгрузки товаров
	const loadMore = async () => {
		try {
			let productList = '';

			productList = await api.cabinetApi.getSourceProductList({
				source_code: code,
				offset: nextOffset,
				filter: {"category_id": currentCategoryId}
			});

			dispatch({
				type: "GET_MORE_PRODUCT_LIST",
				productList: productList.products,
				offset: productList.offset,
				nextOffset: productList.next_offset
			})
			if (!productList.next_offset) {
				setShowMore(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<div className="i_catalog-product-head">
				<div className="i_catalog-product-head-item active">
					<span>Активн.</span>
				</div>
				<div className="i_catalog-product-head-item name">
					<span>Название</span>
				</div>
				<div className="i_catalog-product-head-item article">
					<span>Артикул</span>
				</div>
				<div className="i_catalog-product-head-item update">
					<span>Дата обновления</span>
				</div>
				<div className="i_catalog-product-head-item inner_code">
					<span>Внутренний код</span>
				</div>
				<div className="i_catalog-product-head-item quantity">
					<span>Остаток</span>
				</div>
				<div className="i_catalog-product-head-item price">
					<span>Закуп. цена</span>
				</div>
				<div className="i_catalog-product-head-item rrp">
					<span>Рознич. цена</span>
				</div>
			</div>
			<div className="i_catalog-product-items">
				{productList.length === 0 && (
					<div className="i_catalog-product-empty">
						<div className="i_catalog-page-empty-title">
							<img src={infoImg} alt=""/>
							<span>Товары отсутствуют.</span>
						</div>
					</div>
				)}
				{
					productList.length > 0 ? productList.map((product, index) => {
						return (
							<div className={`i_catalog-product-item`} key={product.id} onClick={() => {
								openCatalogDetailModal(product.id)
							}}>
								<div className="i_catalog-product-item-active">
									<div className={`item-circle ${product.active ? 'active' : ''}`}></div>
								</div>
								<div className="i_catalog-product-item-name">
									<span>{product.name}</span>
								</div>
								<div className="i_catalog-product-item-article">
									<span>{product.vendor_code}</span>
								</div>
								<div className="i_catalog-product-item-update">
									<span>{product.date_update}</span>
								</div>
								<div className="i_catalog-product-item-inner_code">
									<span>{product.inner_code}</span>
								</div>
								<div className="i_catalog-product-item-quantity">
									<span className={'i_catalog-product-item-title'}>Остаток: </span>
									<span>{product.quantity}</span>
								</div>
								<div className="i_catalog-product-item-price">
									<span className={'i_catalog-product-item-title'}>Цена: </span>
									<span>
										{product.prices?.purchase ? product.prices.purchase.print_price : 'Нет цены'}
									</span>
								</div>
								<div className="i_catalog-product-item-rrp">
									<span>
										{product.prices?.retail ? product.prices.retail.print_price : 'Нет цены'}
									</span>
								</div>
							</div>
						);
					}) : ''
				}
				{showMore && (
					<div className={'i_catalog-product-more'}>
						<span onClick={() => {
							loadMore()
						}}>Ещё</span>
					</div>
				)}
			</div>

		</>
	)
}

export default SourceProductList;