import closeBtn from '../../images/closeModal.svg'
import {useDispatch, useSelector} from "react-redux";
import DeleteDistributorCatalog from "../../popup/DeleteDistributorCatalog";
import DeletePriceType from "../../popup/DeletePriceType";
import DeleteSourceCatalog from "../../popup/DeleteSourceCatalog";
import DeleteCategory from "../../popup/DeleteCategory";
import DeleteMyCategory from "../../popup/DeleteMyCategory";
import DeleteSourceCategory from "../../popup/DeleteSourceCategory";

const MultiPopup = () => {

	const dispatch = useDispatch();

	const popupIsOpen = useSelector(state => state.multiPopup.popupIsOpen);
	const popupType = useSelector(state => state.multiPopup.popupType);
	const popupLarge = useSelector(state => state.multiPopup.popupLarge);
	const currentTools = useSelector(state => state.multiTools.currentTools)


	const closeModal = () =>{
		dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false})
	}

	return(
		<div className={`i_multi_popup ${!popupIsOpen ? 'hide' : ''} ${popupLarge ? 'large' : ''}`}>
			<div className="i_container">
				<div className={`i_multi_popup-close`} onClick={()=>{closeModal()}}>
					<img src={closeBtn} alt="close"/>
				</div>

				{/*В зависимоти от переданного значения будет открываться определенное модальное окно*/}
				{popupType === 'deleteDistributorCatalog' && <DeleteDistributorCatalog />}
				{popupType === 'deletePriceType' && <DeletePriceType />}
				{popupType === 'deleteSourceCatalog' && <DeleteSourceCatalog />}
				{popupType === 'deleteCatalogCategory' && currentTools === "distributor-catalogs-inner" && <DeleteCategory />}
				{popupType === 'deleteCatalogCategory' && currentTools === "catalogs-my-inner" && <DeleteMyCategory />}
				{popupType === 'deleteCatalogCategory' && currentTools === "catalogs-source-inner" && <DeleteSourceCategory />}
			</div>
		</div>
	)
}

export default MultiPopup;