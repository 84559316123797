import {useState, useEffect, useRef} from "react";
import logo from '../../images/logo/logo_horizontal.svg'
import successSvg from '../../images/success-form.svg';
import './style.scss'
import {api} from "../../api";
import ReCAPTCHA from "react-google-recaptcha";
import ButtonLoader from "../main/components/ButtonLoader";
import {ReactSVG} from "react-svg";
import {useSelector} from "react-redux";

const AuthForm = () => {
	//Храним состояния полей формы
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [captchaValue, setCaptchaValue] = useState(null);
	const [loadBtn, setLoadBtn] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [message, setMessage] = useState('');
	const [messageColor, setMessageColor] = useState('green');
	const [messageDone, setMessageDone] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(false);
	const [disabledLink, setDisabledLink] = useState(false);
	const [isResetShow, setIsResetShow] = useState(false);
	const [isAuth, setIsAuth] = useState(true);
	const [countryName, setCountryName] = useState('Казахстан');

	//Храним состояние ошибки
	const [error, setError] = useState('');

	//Смотрим на какой версии сайта мы находимся
	const currentDomain = window.location.hostname;
	const domainSegments = currentDomain.split('.');
	const lastSegment = domainSegments.pop();

	//Ссылки на DOM элементы
	const itemCaptcha = useRef(null);
	const passwordInput = useRef(null);
	const currentLogin = useRef(null);

	//Записываем значение reCaptcha в переменную
	const recaptchaChange = (value) => {
		setCaptchaValue(value);
	};

	//Проверка валидности email
	const setIsValidEmail = async (e) => {
		const newEmail = e.target.value;
		setLogin(newEmail);
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
		const isValidEmail = emailRegex.test(newEmail);
		setError('');
		if (isValidEmail) {
			const data = await api.authApi.checkLogin(newEmail);
				if (!isResetShow) {
					if(isAuth){
						if (data.status === true && data.exists === true) {
							setDisabledBtn(false)
							setDisabledLink(true)
							setMessage('')
							setMessageColor('')
						} else {
							setDisabledBtn(true)
							setDisabledLink(false)
							setMessageColor('red')
							setMessage("Пользователь не найден, Вы можете зарегистрироваться");
						}
					}else{
						if (data.status === true && data.exists === true) {
							setDisabledBtn(true)
							setDisabledLink(false)
							setMessageColor('red')
							setMessage("Пользователь уже зарегистрирован, Вы можете авторизоваться")
						} else {
							setDisabledBtn(false)
							setDisabledLink(true)
							setMessageColor('green')
							setMessage("Пользователь свободен, Вы можете зарегистрироваться")
						}
					}
				}
		}else{
			setDisabledBtn(false)
			setDisabledLink(false)
			setMessage('')
			setMessageColor('')
		}
	};

	const checkEmail = async () => {
		if(login && !isResetShow){
			const data = await api.authApi.checkLogin(login);
			if (!isResetShow) {
				if(isAuth){
					if (data.status === true && data.exists === true) {
						setDisabledBtn(false)
						setDisabledLink(true)
					} else {
						setDisabledBtn(false)
						setDisabledLink(false)
						setMessageColor('green')
						setMessage("Пользователь свободен, Вы можете зарегистрироваться");
					}
				}else{
					if (data.status === true && data.exists === true) {
						setDisabledBtn(false)
						setDisabledLink(true)
					} else {
						setDisabledBtn(true)
						setDisabledLink(false)
						setMessageColor('red')
						setMessage("Пользователь не найден, Вы можете зарегистрироваться");
					}
				}
			}
		}else{
			setDisabledBtn(false)
			setMessage('')
			setMessageColor('')
		}
	}

	//Функция отправки формы для авторизации пользователя
	const sendAuthForm = async () => {
		let body = {
			login: login || currentLogin.current.value,
			password: password || passwordInput.current.value
		};

		try {
			setLoadBtn(true)
			const result = await api.authApi.loginUser(body); // Запрашиваем данные аккаунта
			if (result.status === true && result.authorized === true) {
				window.location.reload()
			} else {
				setError(result.message);
			}
		} catch (error) {
			console.log(error) // Выводим ошибку в консоль
		}finally {
			setTimeout(()=>{
				setLoadBtn(false)
			}, 400)
		}
	}

	//Функция отправки формы для регистрации пользователя
	const sendRegForm = async () => {
		if(captchaValue){
			let body = {
				login: login || currentLogin.current.value,
				password: password || passwordInput.current.value,
				recaptcha: captchaValue
			};

			try {
				setLoadBtn(true)
				const result = await api.authApi.registerUser(body); // Запрашиваем данные аккаунта
				if (result.status === true) {
					setIsDone(true)
					setMessageDone(result.message)
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.log(error) // Выводим ошибку в консоль
			}finally {
				setTimeout(()=>{
					setLoadBtn(false)
				}, 400)
			}
		}else{
			setError("Вы точно не робот? \uD83E\uDD28");
		}
	}

	// Обработчик нажатия клавиши Enter
	const handleKeyDown = (event) => {
		if(!disabledBtn){
			if (event.key === 'Enter') {
				if (isResetShow) {
					sendResetPassword();
				} else {
					if (isAuth) {
						sendAuthForm();
					} else {
						sendRegForm();
					}
				}
			}
		}
	};

	// Эффект для подписки и отписки от события клавиши Enter
	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);



	// Переключение видимости кнопки восстановления пароля
	const sendResetPasswordBtn = () => {
		setError('')
		setIsResetShow(!isResetShow);
		setMessage('')
		setDisabledBtn(false)
		// dispatch({type: "SWITCH_MODAL", modalType: 'resetPasswordFormModal', modalIsOpen: true})
	};

	// Отправка запроса на восстановление пароля
	const sendResetPassword = async () => {
		let body = {
			email: login || currentLogin.current.value,
		};
		const data = await api.authApi.sendRestorePassword(body);
		if (data.status === true) {
			setError('');
			setMessageDone(data.message);
			setIsDone(true);
		} else {
			setError(data.message);
		}
	};

	// Получаем текущую страну
	// const siteID = useSelector(state => state.userData.data.country).toLowerCase()

	// Получаем текущую страну и меняем название страны
	const changeCountryName = () => {
		switch (lastSegment) {
			case 'kz':
				setCountryName('Казахстан');
				break;
			case 'uz':
				setCountryName('Узбекистан');
				break;
			case 'one':
				setCountryName('Global');
				break;
			case 'kg':
				setCountryName('Кыргызстан');
				break;
			default:
				setCountryName('Казахстан');
				break;
		}
	}

	// Вызываем функцию при загрузке страницы
	useEffect(()=>{
		changeCountryName()
	},[])

	return (
		<div className="i_auth_form">
			<div className="i_auth-img" onClick={() => {
				window.location.href = `${lastSegment == 'd' ? 'http' : 'https'}://apicore.${lastSegment}`
			}}>
				<img src={logo} alt="apicore"/>

				<div className="i_country-title">
					<span>{countryName}</span>
				</div>
			</div>

			<div className="i_auth_form-title">
				<span>{isAuth ? 'Авторизация' : 'Регистрация'}</span>
			</div>

			<div className="i_auth_form-items">

				{/*INPUT EMAIL*/}

				<div className="i_auth_form-item">
					<label>Email</label>
					<input type="text" name="USER_LOGIN" maxLength="50" value={login}
					       onChange={setIsValidEmail} ref={currentLogin}/>
				</div>

				{/*INPUT ПАРОЛЬ*/}
				{
					!isResetShow && (
						<div className={`i_auth_form-item`}>
							<label>Пароль</label>
							<input type="password" name="USER_PASSWORD" maxLength="50" value={password}
							       onChange={(e) => {
								       setPassword(e.target.value)
							       }}
							       ref={passwordInput}
							/>
						</div>
					)
				}

				{/*СООБЩЕНИЯ*/}
				{
					message && (
						<div className={`i_message ${messageColor === 'green' ? 'green' : 'red'}`}>{message}</div>
					)
				}

				{/*КАПЧА*/}
				{ !isAuth && !isResetShow && (
					<div className={`i_auth_form-item ${isAuth ? 'hd' : ''}`}>
						<div className='i_recaptcha' ref={itemCaptcha}>
							<ReCAPTCHA
								sitekey="6LceO5ooAAAAAD8D-gWZKDrGMMJtWloiqZRVhYSC"
								onChange={recaptchaChange}
								onError={(err) => console.error('Ошибка загрузки капчи:', err)}
								onExpired={() => console.warn('Капча истекла')}
								onLoad={() => console.log('Капча загружена')}
							/>
						</div>
					</div>
				)}


				{/*ВОЙТИ*/}

				<div className={`i_auth_form-btn ${disabledBtn ? 'disabled' : ''}`}>
					<ButtonLoader load={loadBtn}
					              title={isAuth ? !isResetShow ? 'Войти' : 'Восстановить' : 'Зарегистрироваться'}
					              onClick={isAuth ? !isResetShow ? sendAuthForm : sendResetPassword : sendRegForm}/>
				</div>

				{/*РЕГИСТРАЦИЯ*/}
				{
					isAuth && !isResetShow && (
						<div className={`i_auth_form-item link ${isAuth ? '' : 'hd'} ${disabledLink ? 'disabled' : ''}`}>
							<div className="i_auth_form-btn" onClick={() => {
								checkEmail()
								setMessage('')
								setDisabledBtn(false)
								setIsAuth(false)
							}}>
								<span>Зарегистрироваться</span>
							</div>
						</div>
					)
				}

				{/*АВТОРИЗАЦИЯ*/}
				{
					!isAuth && (
						<div className={`i_auth_form-item link ${isAuth ? 'hd' : ''}`}>
							<div className="i_auth_form-btn" onClick={() => {
								checkEmail()
								setMessage('')
								setError('')
								setIsAuth(true)
							}}>
								<span>Авторизация</span>
							</div>
						</div>
					)
				}

				{/*ВОССТАНОВЛЕНИЕ ПАРОЛЯ*/}

				<div className={`i_auth_form-item link ${!isAuth ? 'hd' : ''}`}>
					<div className="i_a_form-item-text">
						<span onClick={sendResetPasswordBtn}>{isResetShow ? 'Вспомнили пароль?' : 'Забыли пароль?'}</span>
					</div>
				</div>

				{/*ОШИБКА*/}

				<div className="i_error-message">
					<span dangerouslySetInnerHTML={{__html: error}}></span>
				</div>
			</div>

			<div className={`i_form_success ${isDone ? '' : 'hd'}`}>
				<ReactSVG src={successSvg}/>
				<span>{messageDone}</span>
			</div>
		</div>
	)
}

export default AuthForm;