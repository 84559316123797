import {api} from "../../../../api";
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import "../styles/distributorPage.scss"
import {useDispatch, useSelector} from "react-redux";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import {COUNTRY_CONFIG} from "../../../../utils/config";


const DistributorPage = () => {
	const [distributorList, setDistributorList] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [loadBtn, setLoadBtn] = useState(false)
	const [currentDistributor, setCurrentDistributor] = useState(null)

	const siteID = useSelector(state => state.userData.data.country).toLowerCase()

	const getDistibutor = async () => {
		setIsLoading(true)
		try {
			const result = await api.cabinetApi.getDistributorList();

			setDistributorList(result.distributors)
			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	// const openDistributorConnectModal = () => {
	// 	dispatch({type: "SWITCH_MODAL", modalType: 'distributorConnectModal', modalIsOpen: true})
	// }

	const connectDistributor = async (id) => {
		setCurrentDistributor(id)
		setLoadBtn(true)
		try {
			const result = await api.cabinetApi.connectDistributor({distributor_id: id})

			if (result.status === true) {
				getDistibutor()
			}

		} catch (e) {
			console.log(e)
		} finally {
			setLoadBtn(false)
			setCurrentDistributor(null)
		}
	}

	useEffect(() => {
		getDistibutor()
	}, [])

	return (
		<div className={`i_distributor-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={16}
							height={'9rem'}
							width={'24%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_distributor-page-skeleton'}
						/>
					</div>
				</>
			)}

			{!isLoading && (
				<div className="i_distributor-items">
					{distributorList.map((distributor, index) => {
						return (
							<div className={`i_distributor-item`} key={index}>
								<div className="i_distributor-item-title">
									<span>{distributor.name}</span>
								</div>
								<div className="i_distributor-item-id">
									{
										distributor.connected ?
											<>
												<span>distributor_id: {distributor.id}</span>
												<CopyToClipboardButton textToCopy={distributor.id}/>
											</>

											: false
									}
								</div>


								<div className="i_dealet-item-contact">
									<span>Контактное лицо: {distributor.contact}</span>
								</div>
								<div className="i_distributor-item-phone">
									<span>Телефон: {distributor.phone}</span>
								</div>
								<div className="i_distributor-item-web">
									<span>{COUNTRY_CONFIG[siteID].binName}: {distributor.bin}</span>
								</div>
								{/*<div className="i_distributor-item-access">*/}
								{/*	<span className={`i_distributor-item-access-status ${distributor.access === "paid" ? "blue" : "green"}`}>*/}
								{/*		{distributor.access === "paid" ? "Платное подключение ( 15 000 тг/мес )" : " Бесплатное подключение"}*/}
								{/*	</span>*/}
								{/*</div>*/}
								{
									distributor.connected_request ?
										<div className={'i_distributor-item-request'}>
											<span>Заявка отправлена</span>
										</div>
										:
										!distributor.connected ?
											<div className={`i_distributor-btn`}>
												<button
													className={`button-loader ${currentDistributor === distributor.id && loadBtn ? `loader` : ''}`}
													onClick={() => {
														connectDistributor(distributor.id)
													}}><span>Подключить</span></button>
											</div>
											:
											<div className={`i_distributor-item-connected`}>
												<span>Подключен</span>
											</div>
								}
							</div>
						);
					})}
				</div>
			)}
			<div>
			</div>
		</div>
	)
}

export default DistributorPage;