import { API_CABINET_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class CabinetApi{

	//API возвращает список меню для sidebar
	//Передаём пустой BODY
	getMenu(){
		return multiFetch(`${API_CABINET_URL}/menu.get`, {}, {});
	}

	//API возвращает заголовок страницы
	//BODY Отправления {"path":""}
	getTitle(body){
		return multiFetch(`${API_CABINET_URL}/title.get`, body, {});
	}

	//API создает Api ключ для клиента
	//Передаём пустой BODY
	generateApiKey(){
		return multiFetch(`${API_CABINET_URL}/account.apikey.generate`, {}, {});
	}

	//API возвращает список Api ключей клиента
	//Передаём пустой BODY
	getApiKeys(){
		return multiFetch(`${API_CABINET_URL}/account.apikey.get`, {}, {});
	}

	//API создает нового дистрибьютора , и меняет статус клиента на дистрибьютора
	//BODY Отправления {"name":"", "bin":"", "web":"", "country":"", "phone":"", "contact":""}
	addDistribution(body){
		return multiFetch(`${API_CABINET_URL}/distributor.add`, body, {});
	}

	//API создает нового дистрибьютора , и меняет статус клиента на дилера
	//BODY Отправления {"name":"", "bin":"", "web":"", "country":"", "phone":"", "contact":""}
	addDealer(body){
		return multiFetch(`${API_CABINET_URL}/dealer.add`, body, {});
	}

	//API возвращает список дилеров
	//Передаём пустой BODY
	getDealerList(body){
		return multiFetch(`${API_CABINET_URL}/dealer.list`, body, {});
	}

	//API возвращает список дистрибьюторов
	//Передаём пустой BODY
	getDistributorList(body){
		return multiFetch(`${API_CABINET_URL}/distributor.list`, body, {});
	}

	//API возвращает список категорий
	//Передаём пустой BODY
	getCatalogCategoryList(body) {
		return multiFetch(`${API_CABINET_URL}/catalog.category.list`, body, {});
	}

	//API возвращает список товаров
	//Передаём пустой BODY
	getCatalogProductList(body) {
		return multiFetch(`${API_CABINET_URL}/catalog.product.list`, body, {});
	}

	//API возвращает список категорий дистрибьюторского каталога
	//Передаём пустой BODY
	getDistributorCatalogCategoryList(body) {
		return multiFetch(`${API_CABINET_URL}/distributor.catalog.category.list`, body, {});
	}

	//API возвращает список товаров дистрибьюторского каталога
	//Передаём пустой BODY
	getDistributorCatalogProductList(body) {
		return multiFetch(`${API_CABINET_URL}/distributor.catalog.product.list`, body, {});
	}

	//API возвращает статистику по дистрибьютерскому каталогу
	//Передаём пустой BODY
	getStatisticCatalog() {
		return multiFetch(`${API_CABINET_URL}/statistic.catalog`, {}, {});
	}

	//API возвращает статистику по дистрибьютерскому каталогу
	//Передаём пустой BODY
	getDistributionStatisticDealer() {
		return multiFetch(`${API_CABINET_URL}/statistic.dealer`, {}, {});
	}

	//API возвращает текущий тариф дистрибьютора для Кабинета
	//Передаём пустой BODY
	getCurrentTariff(){
		return multiFetch(`${API_CABINET_URL}/tariff.current`, {}, {});
	}

	//API возвращает общий список каталога дистрибьютора
	//Передаём пустой BODY
	getCatalogList(){
		return multiFetch(`${API_CABINET_URL}/catalog.list`, {}, {});
	}

	//API возвращает список каталогов дистрибьютора
	//Передаём пустой BODY
	getDistributorCatalogList(){
		return multiFetch(`${API_CABINET_URL}/distributor.catalog.list`, {}, {});
	}

	//API добавляет каталог дистрибьютора
	//BODY Отправления {"name" : "", "code" : ""}
	addCatalog(body){
		return multiFetch(`${API_CABINET_URL}/catalog.add`, body, {});
	}

	//API удаляет каталог дистрибьютора
	//BODY Отправления {"code" : ""}
	deleteCatalog(body){
		return multiFetch(`${API_CABINET_URL}/catalog.delete`, body, {});
	}
	//API изменяет каталог дистрибьютора
	//BODY Отправления {"name" : "", "code" : ""}
	editCatalog(body){
		return multiFetch(`${API_CABINET_URL}/catalog.update`, body, {});
	}

	//API запроса подключения к Дистрибьютору
	//BODY Отправления {"distributor_id" : ""}
	connectDistributor(body){
		return multiFetch(`${API_CABINET_URL}/distributor.request.connect`, body, {});
	}

	//API подверждения подключения к Дистрибьютору
	//BODY Отправления {"dealer_id" : ""}
	dealerApprove(body){
		return multiFetch(`${API_CABINET_URL}/dealer.approve`, body, {});
	}

	//API для получения списка типов цен
	//Передаём пустой BODY
	getPriceType(){
		return multiFetch(`${API_CABINET_URL}/settings.pricetype.list`, {}, {});
	}

	//API возврашает профиль компании
	//Передаём пустой BODY
	getProfile(){
		return multiFetch(`${API_CABINET_URL}/profile.get`, {}, {});
	}

	//API изменяет профиль компании
	//BODY Отправления {"name" : "", "bin" : "", "web" : "", "phone" : "", "contact" : ""}
	editProfile(body){
		return multiFetch(`${API_CABINET_URL}/profile.edit`, body, {});
	}

	//API создает новый тип цены
	//BODY Отправления {   "name":"Тип цены", "code":"Код", "access":[*,*,*]}
	addPriceType(body){
		return multiFetch(`${API_CABINET_URL}/settings.pricetype.add`, body, {});
	}

	//API удаления типа цены
	//BODY Отправления {"code":"Код"}
	deletePriceType(body){
		return multiFetch(`${API_CABINET_URL}/settings.pricetype.delete`, body, {});
	}

	//API редактирования типа цены
	//BODY Отправления {"name":"Тип цены", "access":[*,*,*]}
	editPriceType(body){
		return multiFetch(`${API_CABINET_URL}/settings.pricetype.edit`, body, {});
	}

	//API для проучения списка дилеров
	//Передаём пустой BODY
	getDealerListForm(){
		return multiFetch(`${API_CABINET_URL}/dealer.list.form`, {}, {});
	}

	//API для изменения активности дилера
	//BODY Отправления {"dealer_id" : "", "is_active" : ""}
	setDealerAccess(body){
		return multiFetch(`${API_CABINET_URL}/dealer.set.access`, body, {});
	}

	//API для получения детального описания товара
	//BODY Отправления {"id" : ""}
	getCatalogProductDetail(body){
		return multiFetch(`${API_CABINET_URL}/catalog.product.detail`, body, {});
	}

	//API для получения детального описания товара (Источники)
	//BODY Отправления {"id" : ""}
	getSourceProductDetail(body){
		return multiFetch(`${API_CABINET_URL}/source.product.detail`, body, {});
	}

	//API для получения детального описания товара в кабинете дилера
	//BODY Отправления {"id" : ""}
	getDistributorCatalogProductDetail(body){
		return multiFetch(`${API_CABINET_URL}/distributor.catalog.product.detail`, body, {});
	}

	//API для получения списка категорий источников
	//Передаём пустой BODY
	getMatchingSourceList(){
		return multiFetch(`${API_CABINET_URL}/matching.source.list`, {}, {});
	}

	//API для получения списка моих категорий
	//Передаём пустой BODY
	getMatchingCatalogList(){
		return multiFetch(`${API_CABINET_URL}/matching.catalog.list`, {}, {});
	}

	//API для получения списка категорий
	//BODY Отправления {"source_code": "", "catalog_code": "", "type": "", "distributor_id": ""}
	getMatchingCategoryList(body){
		return multiFetch(`${API_CABINET_URL}/matching.category.list`, body, {});
	}

	//API для матчинга категорий
	//BODY Отправления {"source_category_id": "", "category_id": "", "source_type": "", "catalog_code": ""}
	addMatchCategory(body){
		return multiFetch(`${API_CABINET_URL}/matching.category.add`, body, {});
	}

	//API для удаления матчинга категорий
	//BODY Отправления {"source_category_id":"", "source_type":"", "category_id":""}
	deleteMatchCategory(body){
		return multiFetch(`${API_CABINET_URL}/matching.category.delete`, body, {});
	}

	//API для получения товаров с категорий матчинга
	//BODY Отправления {"type" : "", "category_id" : "", "catalog_code": ""}
	getMatchingProductList(body){
		return multiFetch(`${API_CABINET_URL}/matching.product.list`, body, {});
	}

	//API для получения товаров с категорий матчинга вправом столбце
	//BODY Отправления {"id: "}
	getMatchingProductMatchList(body){
		return multiFetch(`${API_CABINET_URL}/matching.product.matchlist`, body, {});
	}

	//API для матчинга товаров в категории
	//BODY Отправления { "product_id":[id,id], "category_id":"", "source_type":""}
	addMatchProduct(body){
		return multiFetch(`${API_CABINET_URL}/matching.product.add`, body, {});
	}

	//API для удаления матчинга товаров в категории
	//BODY Отправления { "product_id": "" , "catalog_code": ""}
	deleteMatchProduct(body){
		return multiFetch(`${API_CABINET_URL}/matching.product.delete`, body, {});
	}

	//API для получения списка маркетплейсов
	//Передаём пустой BODY
	getMarketplaceList(){
		return multiFetch(`${API_CABINET_URL}/marketplace.list`, {}, {});
	}

	//API для получения списка внешних источников
	//Передаём пустой BODY
	getSourceList(){
		return multiFetch(`${API_CABINET_URL}/source.list`, {}, {});
	}

	//API для добавления внешнего источника
	//BODY Отправления {"name" : "", "code" : ""}
	addSourceList(body){
		return multiFetch(`${API_CABINET_URL}/source.add`, body, {});
	}

	//API для обновления внешнего источника
	//BODY Отправления {"name" : "", "code" : ""}
	editSourceList(body){
		return multiFetch(`${API_CABINET_URL}/source.update`, body, {});
	}

	//API для удаления внешнего источника
	//BODY Отправления { "code" : ""}
	deleteSourceList(body){
		return multiFetch(`${API_CABINET_URL}/source.delete`, body, {});
	}

	// API для получения списка категорий внешних источников
	//BODY Отправления { "code" : ""}
	getSourceCategoryList(body){
		return multiFetch(`${API_CABINET_URL}/source.category.list`, body, {});
	}

	// API для получения списка товаров внешних источников
	//BODY Отправления { "code" : "", "offset" : ""}
	getSourceProductList(body){
		return multiFetch(`${API_CABINET_URL}/source.product.list`, body, {});
	}

	// API для добавления категории в моих каталогах
	//BODY Отправления { "name" : "", "parent_id" : "", "catalog_code" : ""}
	addCatalogCategory(body){
		return multiFetch(`${API_CABINET_URL}/catalog.category.add`, body, {});
	}

	// API для добавления категории во внешние источники
	//BODY Отправления { "name" : "", "parent_id" : "", "source_code" : ""}
	addSourceCategory(body){
		return multiFetch(`${API_CABINET_URL}/source.category.add`, body, {});
	}

	updateCatalogCategory(body){
		return multiFetch(`${API_CABINET_URL}/catalog.category.update`, body, {});
	}

	updateSourceCategory(body){
		return multiFetch(`${API_CABINET_URL}/source.category.update`, body, {});
	}

	deleteCatalogCategory(body){
		return multiFetch(`${API_CABINET_URL}/catalog.category.delete`, body, {});
	}

	deleteSourceCategory(body){
		return multiFetch(`${API_CABINET_URL}/source.category.delete`, body, {});
	}

	// API для поиска товаров по названию
	// BODY Отправления {"query" : ""}
	searchTitle(body){
		return multiFetch(`${API_CABINET_URL}/search.title`, body, {});
	}

	// API для получения списка фотографий товара
	// BODY Отправления {"id" : ""}
	getProductImagesList(body){
		return multiFetch(`${API_CABINET_URL}/catalog.product.image.list`, body, {});
	}

	// API для сохранения фотографий товара
	// BODY Отправления
	// {"id":123, "add":[// объект $_FILES с изображениями// ], "delete":[// 1,2,3 // ID изображений для удаления// ]// }
	saveProductImages(formData){
		return fetch(`${API_CABINET_URL}/catalog.product.image.save`,{
			body: formData,
			method: 'POST',
			credentials: 'include'
		}).then(response => response.json());
	}
}




