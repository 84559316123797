import {ReactSVG} from "react-svg";
import plus from '../../../images/catalogs/category/add-category.svg'
import {useDispatch} from "react-redux";

const AddCategory = () => {

	const dispatch = useDispatch();

	return(
		<div className={`i_multitools-item`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Добавить категорию'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type: "OPEN_MODAL", modalType: 'addCategoryModal', modalLevel: 1})}
		>
			<ReactSVG src={`${plus}`} className={'i_multitools-item-svg'}/>
		</div>
	)
}

export default AddCategory;