import {useSelector} from "react-redux";
import {MULTITOOLS_CONFIG} from "../../utils/config";

const MultiTools = () => {
	// Получаем диспатч и текущий URL


	// Получаем текущий мультитул из стейта
	const currentTools = useSelector(state => state.multiTools.currentTools)

	// Если текущий мультитул есть, то рендерим его
	return (
		<div className={`i_multitools ${!currentTools ? 'hide' : ''}`}>
			{currentTools && (
				MULTITOOLS_CONFIG.pages[currentTools].components.map((Component, index) => {
					return <Component key={index}/>
				})
			)}
		</div>
	)
}

export default MultiTools;