import {useDispatch, useSelector} from "react-redux";

const Overlay = () =>{
	const dispatch = useDispatch();
	const modalIsOpenLevel1 = useSelector(state => state.multiModal.modals[0].modalIsOpen);
	const modalIsOpenLevel2 = useSelector(state => state.multiModal.modals[1].modalIsOpen);
	const modalIsOpenLevel3 = useSelector(state => state.multiModal.modals[2].modalIsOpen);
	const popupIsOpen = useSelector(state => state.multiPopup.popupIsOpen);
	const showTabBar = useSelector(state => state.menu.showTabBar);
	const showTabBarCatalog = useSelector(state => state.menu.showTabBarCatalog);

	const changeShowOverlay = (lvl) =>{
		dispatch({type: "CLOSE_MODAL", modalLevel: lvl})
		dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
		dispatch({type: "SHOW_TABBAR_MORE", showTabBar: false})
		dispatch({type: "SHOW_TABBAR_CATALOG", showTabBarCatalog: false})
	}

	return(
		<>
			<div
				className={`i_overlay overlay-level-1 ${modalIsOpenLevel1 || showTabBar || popupIsOpen || showTabBarCatalog ? '' : 'hide'}`}
				onClick={()=> changeShowOverlay(1)}></div>
			<div
				className={`i_overlay overlay-level-2 ${modalIsOpenLevel2 ?  '' : 'hide'}`}
				onClick={()=> changeShowOverlay(2)}></div>
			<div
				className={`i_overlay overlay-level-3 ${modalIsOpenLevel3  ? '' : 'hide'}`}
				onClick={()=> changeShowOverlay(3)}></div>
		</>
	)
}

export default Overlay;