import logo from '../../../images/logo/logo_horizontal.svg'
import avatar from '../../../images/avatar.svg'
import LanguageSelector from "../../components/LanguageSelector";
import i18n from "../../../i18n";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CountryViewer from "../../components/CountryViewer";

const SelectRole = () => {
	//Нужно для перевода
	const {t} = useTranslation();

	//Записываем и достаем данные из локального хранилища
	const [language, setLanguage] = useLocalStorage('language', 'ru')

	const dispatch = useDispatch();
	const userData = useSelector(state => state.userData.data)


	//Изменияем язык
	const setCurrentLanguage = (el) =>{
		window.location.reload();
		i18n.changeLanguage(el)
		setLanguage(el)
	}

	return (
		<div className={'i_select-role'}>
			<div className="container">
				<div className="i_header">
					<div className="i_header-items">
						<div className="i_header-item logo">
							<img src={logo} alt="logo"/>
						</div>
						<div className="i_header-item">
							<CountryViewer />
						</div>
						<div className="i_header-item-right">
							{/*<LanguageSelector setCurrentLanguage={setCurrentLanguage} language={language} showTitle={true}/>*/}

							<div className="i_header-item account">
								<img src={avatar} alt="user"/>
								<span>{userData.user.login}</span>
							</div>
						</div>

					</div>
					<div className="i_select-content">
						<div className="i_select-text">
							<div className="i_select-title">
								<span>{t("I_SELECT_ROLE_WELCOME")}</span>
							</div>
							<div className="i_select-subtitle">
						      <span>{t("I_SELECT_ROLE_WELCOME_SUBTITLE")}</span>
								<br/><br/>
								<span>{t("I_SELECT_ROLE_WELCOME_SUBSUBTITLE")}</span>
							</div>
						</div>
						<div className="i_select-roles">
							<div className="i_roles-items">
								<div className="i_roles-item">
									<div className="i_role-item-title">
										<span>{t("I_SELECT_ROLE_DISTRIBUTOR_NAME")}</span>
									</div>
									{/*<div className="i_role-item-subtitle">*/}
									{/*	<span>{t("I_SELECT_ROLE_DISTRIBUTOR_SUBTITLE")}</span>*/}
									{/*</div>*/}
									<div className="i_role-text">
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_1")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_2")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_3")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_4")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_5")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_6")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DISTRIBUTOR_LIST_7")}</span>
										</div>
									</div>
									<div className="i_role-btn" onClick={() =>{
										dispatch({type: "OPEN_MODAL", modalType: 'selectRoleModalDistributor', modalLevel: 1})
									}}>
										<span>{t("I_SELECT_ROLE_DISTRIBUTOR_BTN")}</span>
									</div>
								</div>

								<div className="i_roles-item">
									<div className="i_role-item-title">
										<span>{t("I_SELECT_ROLE_DEALER_NAME")}</span>
									</div>
									{/*<div className="i_role-item-subtitle">*/}
									{/*	<span>{t("I_SELECT_ROLE_DEALER_SUBTITLE")}</span>*/}
									{/*</div>*/}
									<div className="i_role-text">
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_1")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_2")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_3")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_4")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_5")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_6")}</span>
										</div>
										<div className="i_role-text-item">
											<span>{t("I_SELECT_ROLE_DEALER_LIST_7")}</span>
										</div>
									</div>
									<div className="i_role-btn" onClick={() =>{
										dispatch({type: "OPEN_MODAL", modalType: 'selectRoleModalDealer', modalLevel: 1})
									}}>
										<span>{t("I_SELECT_ROLE_DEALER_BNT")}</span>
									</div>
								</div>
							</div>
						</div>
						{/*<div className="i_select-roles-ps">*/}
						{/*	<span>{t("I_SELECT_ROLE_FORM_PS")}</span>*/}
						{/*</div>*/}
					</div>
				</div>
			</div>
		</div>
	)
}

export default SelectRole;