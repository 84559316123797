import Skeleton from "react-loading-skeleton";
import {api} from "../../../../api";
import {useEffect, useState} from "react";
import DealerProductList from "../../components/dealer/catalogPage/DealerProductList";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "../styles/catalogPage.scss"
import OpenCatalogs from "../../components/OpenCatalogs";
import CloseCatalogs from "../../components/CloseCatalogs";
import CategoryListNew from "../../components/CategoryListNew";
import CategoryListMobile from "../../components/CategoryListMobile";

const CurrentDistributorCatalogPage = () =>{
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch();

	let { distributor_id , code } = useParams();

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const categoryList = await api.cabinetApi.getDistributorCatalogCategoryList({code: code, distributor_id : distributor_id});
			const productList = await api.cabinetApi.getDistributorCatalogProductList({code: code ,  distributor_id : distributor_id, offset:0});

			//Записываем в стейт список товаров
			dispatch({type: "GET_PRODUCT_LIST", productList: productList.products, offset: productList.offset, nextOffset: productList.next_offset || null})
			dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: categoryList.categories})
			dispatch({type: "SET_DISTRIBUTOR_ID", distributor_id: distributor_id})

			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		dispatch({type: "SET_CATALOG_CODE", catalogCode: code})

		// Устанавливаем текущий мультитул
		// dispatch({type: "SET_MULTITOOLS", currentTools: "catalogs-distributor-inner"})

		// Получаем список категорий и товаров
		getList()
	}, [])

	//Достаем список товаров из стейта
	const productListState = useSelector(state => state.catalog.productList.productList);
	const categoryList = useSelector(state => state.catalog.categoryList.categoryList);

	return(
		<div className={`i_catalog-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height:"100%"}}>
						<Skeleton
							count={2}
							height={'100%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-in-skeleton'}
						/>
					</div>
				</>
			)}
			{!isLoading && categoryList && productListState &&(
				<div className={`i_catalog-main`}>
					{/*Выводим список категорий с вложенностями*/}
					<div className="i_catalog-category">
						<div className="i_catalog-tools">
							<div></div>
							<div className="i_catalog-tools-right">
								<OpenCatalogs/>
								<CloseCatalogs/>
							</div>
						</div>
						<div className="i_catalog-category-items">
							<CategoryListNew/>
						</div>
					</div>

					{/*Мобильная версия категорий*/}
					<CategoryListMobile />

					{/*Выводим список товаров*/}
					<div className="i_catalog-product">
						<DealerProductList productList={productListState} hideProductCard={true}/>
					</div>
				</div>

			)}
		</div>
	)
}

export default CurrentDistributorCatalogPage;