import {useSelector} from "react-redux";
import Profile from "./Profile";
import SearchBar from "./SearchBar";

const Header = () => {
	const title = useSelector(state => state.appData.title)

	return (
		<div className={'header'}>
			<div className="header-left">
				<div className="header-title">
					<span>{title}</span>
				</div>
			</div>

			<div className="header-right">
				<SearchBar/>
				<Profile/>
			</div>
		</div>
	);
}

export default Header;