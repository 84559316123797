import {useEffect, useState} from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import MainPageCatalogWidget from "../../components/distributor/mainPage/MainPageCatalogWidget";
import {ReactSVG} from "react-svg";
import {api} from "../../../../api";
import MainPageDealerWidget from "../../components/distributor/mainPage/MainPageDealerWidget";
import "../styles/mainPage.scss"

//Личный кабинет/главная страница
const MainPage = () => {
	//Создаем состояния для компонентов и виджетов
	const [isLoading, setIsLoading] = useState(true)

	const [catalogStatistic, setCatalogStatistic] = useState(null);
	const [catalogStatisticIcon, setCatalogStatisticIcon] = useState(null)

	const [dealerStatistic, setDealerStatistic] = useState(null);
	const [dealerStatisticIcon, setDealerStatisticIcon] = useState(null)

	//Ассинхронная функция получения статистики с сервера
	const getCatalogStatistic = async () => {
		try {
			const catalogStatisticResponse = await api.cabinetApi.getStatisticCatalog();
			const distributionStatisticDealerResponse = await api.cabinetApi.getDistributionStatisticDealer();

			setCatalogStatisticIcon(catalogStatisticResponse.statistic.icon)
			setCatalogStatistic(catalogStatisticResponse.statistic.categories.list)

			setDealerStatisticIcon(distributionStatisticDealerResponse.statistic.icon)
			setDealerStatistic(distributionStatisticDealerResponse.statistic)

			setIsLoading(false)

		} catch (error) {
			console.log(error);
		}
	};

	//Запускаем функцию при загрузке страницы
	useEffect(() => {
		getCatalogStatistic();
	}, []);

	return (
		<div className={'i_main-page'}>
			{/*Если данные полностью загрузились , то показываем виджеты*/}
			{!isLoading && (
				<div className="i_main-widgets">
					<div className="i_main-widget">
						<div className="i_main-widget-header">
							<MainPageCatalogWidget catalogStatistic={catalogStatistic}/>
						</div>
						<div className="i_main-widget-bottom">
							<div className="i_main-widget-title">
								<ReactSVG src={catalogStatisticIcon} className={'i_main-widgets-svg'}/>
								<span>Торговый каталог</span>
							</div>
							<div className="i_main-widget-items">
								{catalogStatistic.map((item, index) => {
									return (
										<div className="i_main-widget-item" key={index}>
											<div className="i_main-widget-item-title">
												<span>{item.name}</span>
											</div>
											<div className="i_main-widget-item-count">
												<span>{item.value} тов.</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
					<div className="i_main-widget">
						<div className="i_main-widget-header">
							<MainPageDealerWidget dealerStatistic={dealerStatistic}/>
						</div>
						<div className="i_main-widget-bottom">
							<div className="i_main-widget-title">
								<ReactSVG src={dealerStatisticIcon} className={'i_main-widgets-svg'}/>
								<span>Дилеры</span>
							</div>
							<div className="i_main-widget-items">
								{dealerStatistic.dealers.map((item, index) => {
									return (
										<div className="i_main-widget-item" key={index}>
											<div className="i_main-widget-item-title">
												<span>{item.name}</span>
											</div>
											<div className={`i_main-widget-item-active ${item.active ? 'active' : ''}`}>
												<span>{item.active ? 'Активный' : 'Не активный'}</span>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			)}
			{/*Если данные не загрузились, то показываем Skeleton*/}
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={3}
							height={'80vh'}
							width={'30%'}
							inline={true}
							borderRadius={30}
							containerClassName={'i_main-page-skeleton'}
						/>
					</div>
				</>
			)}


		</div>
	)
}

export default MainPage;


